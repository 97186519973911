<template>
  <!-- COMBO BOX CLIENTE -->
  <div>

    <div>

      <b-col>
        <b-form-group>
          <b-row>
            <b-col cols="4">
              <b-form-group
                description="Selecione o CNPJ."
              >
                <combo-box-client ref="cboxclient" />
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group>
                <b-button
                  variant="primary"
                  type="button"
                  @click="rulesTable()"
                >
                  Carregar
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>

    </div>

    <b-table
      id="regra"
      ref="regra_001"
      hover
      responsive
      :items="items"
      :fields="fields"
    >

      <template #cell(actions)="row">
        <!-- Visualizar De/Para Button -->
        <b-button
          size="sm"
          class="mr-1"
          @click="deparaInfo(row.item, $event.target)"
        >
          Visualizar De/Para
        </b-button>

        <!-- Deletar REGRA Button -->
        <b-button
          size="sm"
          class="mr-1"
          @click="modalBeforeDelRule(row.item, $event.target)"
        >
          Deletar
        </b-button>
      </template>
    </b-table>
    <!-- Nova Regra Button -->
    <b-button
      v-b-modal.nova-regra
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
    >
      Nova Regra
    </b-button>
    <!-- Delete Regra Modal  -->
    <div>

      <b-modal
        :id="modalDeleteRule.id"
        ref="modal-delete-rule"
        :title="modalDeleteRule.title"
        :content="modalDeleteRule.content"
        button-size="sm"
        hide-footer
      >
        <div>
          <!-- Como renderizar o cnpj específico que foi clicado? -->
          Tem certeza que deseja excluir esta Regra?
        </div>

        <!-- Botão cancelar dentro do botão delete -->
        <b-col class="demo-inline-spacing">
          <b-button
            id=""
            variant="primary"
            type="button"
            @click="hideModal2()"
          >
            Cancelar
          </b-button>

          <!-- Excluir button -->
          <b-button
            id="deleteRuleButton"
            variant="primary"
            type="button"
            @click.prevent="deleteRule()"
          >
            Excluir
          </b-button>
        </b-col>
      </b-modal>
    </div>

    <!-- Component Modal Visualizar De/Para -->
    <modal-depara ref="modaldepara" />
    <modal-depara-new ref="modaldeparanew" />
    <!-- Component Nova Regra Button -->
    <modal-new-rule />
    <!-- Upload files component -->
    <div class="mt-1">
      <upload-params />
    </div>
    <validate-logon-keycloak ref="validatelogon" />

    <!-- TOAST  -->
    <div>
      <b-toast id="example-toast">
        <template #toast-title>
          <div class="d-flex flex-grow-1 align-items-center mr-1">

            <strong class="mr-auto">Selecione CNPJ</strong>
          </div>
        </template>
        <span>Hello, world! This is a toast message. Hope you're doing well.. :)</span>
      </b-toast>
    </div>
  </div>

</template>

<script>
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import {
  BToast, BTable, BButton, BRow, BFormGroup, BCol,

} from 'bootstrap-vue'
import UploadParams from './UploadParams.vue'
import ComboBoxClient from '../reusable-components/ComboBoxClient.vue'
import ModalDepara from './ModalDepara.vue'
import ModalDeparaNew from './ModalDeparaNew.vue'
import ModalNewRule from './ModalNewRule.vue'
import ValidateLogonKeycloak from '../reusable-components/ValidateLogonKeycloak.vue'

export default {
  name: 'ParametrosFisc',
  components: {
    UploadParams,
    ModalDepara,
    ModalDeparaNew,
    BToast,
    ModalNewRule,
    BRow,
    BTable,
    BButton,
    ComboBoxClient,
    BFormGroup,
    BCol,
    ValidateLogonKeycloak,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      modalDeleteRule: {
        id: 'modalDeleteRule',
        title: '',
        content: 'Excluir',
      },
      fields: [
        {
          key: 'table_name', label: 'Interface',
        },
        {
          key: 'desc', label: 'Descricao',
        },
        { key: 'actions', label: 'Actions' },
      ],
      items: [],

    }
  },
  mounted() { // Mounted has to be used when using a Child and trigger after the import
    this.validateUserLogon()
    this.setUser()
  },
  // created() {
  //   this.rulesTable()
  // },
  methods: {
    validateUserLogon() {
      this.$refs.validatelogon.validateLogon()
    },
    setUser() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      this.$parent.$parent.$parent.$refs.adenav.username = userData.fullName
    },
    modalBeforeDelRule(item, button) {
      this.modalDeleteRule.title = `${item.id_conversion}`

      this.$root.$emit('bv::show::modal', this.modalDeleteRule.id, button)// Faz o modal aparecer
    },
    toast(toaster, append = false, variant, message, title) {
      this.$bvToast.toast(message, {
        title,
        toaster,
        solid: true,
        appendToast: append,
        variant,
      })
    },
    hideModal2() {
      this.$refs['modal-delete-rule'].hide()
    },
    async deleteRule() {
      try {
        console.log(this.modalDeleteRule.title)
        await axios.delete(`/custom_conversion/${this.modalDeleteRule.title}`)
        this.toast('b-toaster-top-right', true, 'success', 'Regra excluída com sucesso!', 'Regra Deletada')
        // "this.toast"onde chama o metodo que esta acima qndo se clica no botão.
        this.rulesTable()
        this.hideModal2()
      } catch (error) {
        console.log(error)
        this.toast('b-toaster-top-right', true, 'danger', ` ${error}`)
        if (error.response.status === 500) {
          this.toast('b-toaster-top-right', true, 'danger', ` ${error}`)
        } else {
          this.toast('b-toaster-top-right', true, 'warning', `Regra ${this.modalDeleteRule.title} não encontrada`, 'Deletar Regra')
        }
      }
    },
    async rulesTable() {
      this.isBusy = true
      try {
        const selectedCnpj = this.$refs.cboxclient.selected
        if (selectedCnpj === null) {
          this.toast('b-toaster-top-right', true, 'danger', 'Selecione um cliente!', 'Erro de usabilidade')
          throw new Error('Selecione um cliente!')
        }
        const res = await axios.get(`/custom_conversion/${selectedCnpj}`)
        this.items = res.data
        // this.totalRows = this.btable_rule_items.length
        // this.currentPage = 1
        console.log(res.data)
      } catch (error) {
        console.log(error)
      }
      this.isBusy = false
    },

    deparaInfo(item, index, button) {
      // const selectedCnpj = this.$refs.cboxclient.selected
      // console.log(selectedCnpj)

      const idConversion = item.id_conversion

      this.$refs.modaldepara.id_conversion = idConversion
      this.$refs.modaldeparanew.id_conversion = idConversion
      this.$refs.modaldeparanew.getSafxCol(item.id_safx)

      this.$refs.modaldepara.deparaModal.title = item.table_name
      this.$root.$emit('bv::show::modal', this.$refs.modaldepara.deparaModal.id, button)
      this.$refs.modaldepara.getDePara(idConversion)
      // feito para ser acionado na tag <a> quando apertar a linha da b-table (id="dePara")  e mostar os de para cadastrados.
    },
  },
}
</script>
