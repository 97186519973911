<template>
  <div>
    <!-- Modal Visualizar DE/PARA Info modal -->
    <b-modal
      :id="deparaModal.id"
      size="lg"
      :title="deparaModal.title"
      hide-footer
    >
      <b-form-group
        label="Id"
      >
        <b-form-input
          id=""
          ref="id_conversion"
          v-model="id_conversion"
          :readonly="readonly"
        />
      </b-form-group>
      <b-table
        :id="deparaModal.id"
        ref="depara_001"
        hover
        responsive
        class="mt-1"
        :items="items"
        :fields="fields"
      >

        <template #cell(actions)="row">

          <!-- Expandir Button -->
          <b-button
            size="sm"
            class="mr-1"
            @click="row.toggleDetails"
          >
            {{ row.detailsShowing ? '-' : '+' }}
          </b-button>
        </template>
        <template #row-details="row">
          <b-table
            :fields="fields_depara"
            :items="row.item.list_rules"
          >
            <template #cell(actions)="row">
              <!-- Deletar REGRA Button -->
              <b-button
                size="sm"
                class="mr-1"
                @click.prevent="modalBeforeDelDepara(row.item, $event.target)"
              >
                Deletar
              </b-button>
            </template>

          </b-table>
        </template>
      </b-table>
      <b-button
        size="sm"
        class="mr-1"
        @click.prevent="openModalNewDePara($event.target)"
      >
        Adicionar de/para
      </b-button>
    </b-modal>
    <!-- MODAL DELETE -->
    <b-modal
      :id="modalDeleteDepara.id"
      ref="modal-delete-depara"
      :title="modalDeleteDepara.title"
      :content="modalDeleteDepara.content"
      button-size="sm"
      hide-footer
      no-close-on-backdrop
    >
      <div>
        <!-- Como renderizar o cnpj específico que foi clicado? -->
        Tem certeza que deseja excluir De/Para ?
      </div>
      <b-col class="demo-inline-spacing">
        <b-button
          id=""
          variant="primary"
          type="button"
          @click="$bvModal.hide('modalDeleteDepara')"
        >
          Cancelar
        </b-button>

        <!-- BUTTON excluir De/para  -->
        <div>
          <b-button
            variant="primary"
            type="button"
            @click="deleteDepara()"
          >
            Excluir
          </b-button>
        </div>
      </b-col>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import {
  BButton, BModal, BCol, BTable, BFormInput, BFormGroup,
} from 'bootstrap-vue'

export default {
  name: 'ModalDepara',
  components: {
    BCol,
    BButton,
    BModal,
    BTable,
    BFormInput,
    BFormGroup,
  },
  data() {
    return {
      id_conversion: null,
      readonly: true,
      deparaModal: {
        id: 'depara',
        title: '',
        content: '',
        id_conversion: '', // Used to get the ID from Parent BTable
      },
      modalDeleteDepara: {
        id: 'modalDeleteDepara',
        title: '',
        content: 'Excluir',
      },
      fields: [
        { key: 'col_name', label: 'Coluna' },
        { key: 'actions', label: 'Actions' },
      ],
      fields_depara: [
        {
          key: 'from_rule', label: 'De',
        },
        { key: 'to_rule', label: 'Para' },
        { key: 'actions', label: 'Actions' },
      ],
      items: [],
    }
  },
  methods: {
    openModalNewDePara(button) {
      this.$root.$emit('bv::show::modal', this.$parent.$refs.modaldeparanew.novodePara.id, button)
    },
    toast(toaster, append = false, variant, message, title) {
      this.$bvToast.toast(message, {
        title,
        toaster,
        solid: true,
        appendToast: append,
        variant,
      })
    },
    modalBeforeDelDepara(item, button) {
      this.modalDeleteDepara.title = `${item.id_rule}`

      this.$root.$emit('bv::show::modal', this.modalDeleteDepara.id, button)// Faz o modal aparecer
    },
    hideModalDelete() {
      this.$refs['modal-delete'].hide()
    },
    hideModalDeleteDepara() {
      this.$refs['modal-delete-depara'].hide()
    },

    async getDePara(idConversion) {
      this.isBusy = true
      try {
        const res = await axios.get(`/custom_conversion_rule/all/${idConversion}`)
        this.items = res.data
        console.log(res.data)
        // Setting the id on the new depara modal window
      } catch (error) {
        console.log(error)
      }
      this.isBusy = false
    },
    async deleteDepara() {
      try {
        await axios.delete(`/custom_conversion_rule/${this.modalDeleteDepara.title}`)

        // updates the De Para table, id_conversion(the v-model name of an input )
        this.getDePara(this.id_conversion)

        this.toast('b-toaster-top-right', true, 'success', 'De/Para excluído com sucesso!', 'De/Para Deletado')
        // "this.toast"onde chama o metodo que esta acima qndo se clica no botão.
        this.hideModalDeleteDepara()
      } catch (error) {
        console.log(error)
        this.toast('b-toaster-top-right', true, 'danger', ` ${error}`)
        if (error.response.status === 500) {
          this.toast('b-toaster-top-right', true, 'danger', ` ${error}`)
        } else {
          this.toast('b-toaster-top-right', true, 'warning', `Regra ${this.modalBeforeDelDepara.title} não encontrada`, 'Deletar Regra')
        }
      }
    },
  },

}
</script>
